import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import './custom-times.css';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import moment from 'moment';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ModalTimeEdit from './modalTimeEdit';
import ModalTimeException from './modalTimeException';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import InputSelect from '../../utilities/InputSelect';
import { UIButton } from '../../utilities/Button';
import UITypography from '../../utilities/Typography';
import { UiDialog } from '../../utilities/Dialog';

const CalendarTimes = () => {
  const [times, setTimes] = useState([]);
  const [formattedIntervals, setFormattedIntervals] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [holidaysMode, setHolidaysMode] = useState(false);
  const [holidaysAddedMode, setHolidaysAddedMode] = useState(false);
  const [addedHolidays, setAddedHolidays] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [timesExceptions, setTimesExceptions] = useState([]);
  const [selectedException, setSelectedException] = useState(null);
  const [exceptionMode, setExceptionMode] = useState(false);
  const [open, setOpen] = React.useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false
  });

  const { onListOffices } = useOffices();
  const { onListTimesDoctor, onListExceptionsTimesDoctor, onCreateHolidaysBlocks } = useCalendar();

  useEffect(() => {
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  const listTimes = () => {
    onListTimesDoctor({
      office_id: selectedOffice?.id
    }).then((res) => {
      setTimes(res);
      formatIntervals(res);
      setSelectedException(null);
      setSelectedTime(null);
      setEditMode(false);
      setExceptionMode(false);
    });
  };

  useEffect(() => {
    listTimes();
    onListExceptionsTimesDoctor({
      office_id: selectedOffice?.id
    }).then((res) => {
      let new_array = [];
      res?.forEach((item) => {
        if (item?.intervals?.length === 0) return;
        new_array = [...new_array, ...item.intervals];
      });
      setTimesExceptions(new_array);
    });
  }, [selectedOffice?.id]);

  const handleHolidaysBlocks = () => {
    onCreateHolidaysBlocks({
      office_id: selectedOffice?.id,
      year: Number(selectedYear)
    }).then((res) => {
      setAddedHolidays(res);
      setHolidaysAddedMode(true);
    });
  };

  const handleCloseReport = () => {
    setHolidaysAddedMode(false);
    setAddedHolidays([]);
  };

  const handleModalClose = () => {
    listTimes();
    onListExceptionsTimesDoctor({
      office_id: selectedOffice?.id
    }).then((res) => {
      let new_array = [];
      res?.forEach((item) => {
        if (item?.intervals?.length === 0) return;
        new_array = [...new_array, ...item.intervals];
      });
      setTimesExceptions(new_array);
    });
  };

  const convertDay = (day, intervals_length) => {
    let dayText;

    switch (day) {
      case 1:
        dayText = 'Lunedì';
        break;
      case 2:
        dayText = 'Martedì';
        break;
      case 3:
        dayText = 'Mercoledì';
        break;
      case 4:
        dayText = 'Giovedì';
        break;
      case 5:
        dayText = 'Venerdì';
        break;
      case 6:
        dayText = 'Sabato';
        break;
      case 0:
        dayText = 'Domenica';
        break;
      case -1:
        dayText = 'Eccezioni';
        break;
      default:
        break;
    }

    return (
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Box
          sx={{ bgcolor: intervals_length > 0 ? '#FE922E' : 'transparent', width: '11px', height: '11px', borderRadius: '100%', mr: '5px' }}
        />
        <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'14px'}>
          {dayText}
        </Typography>
      </Box>
    );
  };

  const formatIntervals = (times) => {
    let new_array = [];
    times?.forEach((time) => {
      time?.intervals?.forEach((item) => {
        let index = new_array.findIndex((x) => {
          return x.find((y) => y?.group_uuid === item?.group_uuid);
        });

        if (index >= 0) {
          new_array[index] = [...new_array[index], item];
        } else {
          new_array?.push([item]);
        }
      });
    });
    setFormattedIntervals(new_array);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalTimeEdit
        modalOpen={editMode}
        handleModalClose={handleModalClose}
        calendar_day={selectedDay}
        interval={selectedTime}
        setEditMode={setEditMode}
      />
      <ModalTimeException
        modalOpen={exceptionMode}
        handleModalClose={handleModalClose}
        office={selectedOffice?.id}
        exception={selectedException}
      />

      <UiDialog
        type={'holidays_choose'}
        title={'Aggiungi automaticamente feste nazionali'}
        subtitle={"Seleziona l'anno per cui vuoi aggiungere le festività nazionali al calendario selezionato"}
        open={holidaysMode}
        onClose={() => setHolidaysMode(false)}
        onConfirm={handleHolidaysBlocks}
        onSecondary={(e) => setSelectedYear(e)}
        value={selectedYear}
        values={[
          { value: '2022', label: '2022' },
          { value: '2023', label: '2023' },
          { value: '2024', label: '2024' },
          { value: '2025', label: '2025' },
          { value: '2026', label: '2026' },
          { value: '2027', label: '2027' },
          { value: '2028', label: '2028' },
          { value: '2029', label: '2029' },
          { value: '2030', label: '2030' }
        ]}
      />

      <UiDialog
        type={'holidays_result'}
        title={'Festività aggiunte'}
        subtitle={ (addedHolidays?.length > 0)  ? 'Festività aggiunte al calendario perchè non presenti:' : 'Nessuna ulteriore festività aggiunta al calendario, perchè già tutte presenti.'}
        open={holidaysAddedMode}
        onClose={() => setHolidaysAddedMode(false)}
        onConfirm={handleCloseReport}
        values={addedHolidays}
      />

      <UIPageHeader
        title={'Gestione orari'}
        subtitle={
          'Inserisci i tuoi orari di attività nei rispettivi studi. Agli utenti saranno visibili solo le ripetizioni nei sei mesi coperti dal calendario, ma è possibile impostarle anche per il periodo successivo.'
        }
      />

      <SectionHeader title={'Ufficio'} />
      <Box mb={2}>
        <InputSelect
          label={''}
          options={offices?.map((doctor) => {
            return {
              id: Number(doctor?.id),
              label: `${doctor?.name}`
            };
          })}
          value={selectedOffice}
          setValue={setSelectedOffice}
        />
      </Box>
      <Divider />
      {formattedIntervals &&
        times?.map((row, index) => (
          <React.Fragment key={index}>
            <ListItemButton
              onClick={() =>
                setOpen({
                  ...open,
                  [row?.day]: !open[row?.day]
                })
              }
            >
              <ListItemText primary={convertDay(row?.day, row?.intervals?.length)} />
              {open[row?.day] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[row?.day]} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                sx={{
                  mb: 2
                }}
              >
                {formattedIntervals?.map((interval, index) => {
                  let intervalData = interval.find((x) => x?.calendar_day_id === row?.id);

                  if (!intervalData) return;

                  return (
                    <ListItem
                      key={index}
                      sx={{
                        pl: 4,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '10px'
                      }}
                    >
                      <Box>
                        <UIButton
                          type={'submit'}
                          variant={'copy'}
                          onClick={() => {
                            setSelectedTime(interval);
                            setSelectedDay(row);
                            setEditMode(true);
                          }}
                          icon={<IconEdit aria-label="delete" stroke={1.5} size="20px" />}
                          sxCustom={{
                            bgcolor: '#FDF2B5',
                            color: '#344054',
                            boxShadow: ' 0px 4px 4px 0px #00000026',
                            width: '40px',
                            height: '40px'
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          minWidth: '100px'
                        }}
                      >
                        {interval?.map((item, index) => (
                          <UITypography key={index} variant={'Oxygen700'} title={`${item.start_hour} - ${item.end_hour}`} size={'13px'} />
                        ))}
                      </Box>
                      <Box>
                        <UITypography
                          variant={'Oxygen400'}
                          title={
                            `Ogni ${intervalData?.repeat_every} settimana/e dal ${moment(intervalData?.start_date).format('DD/MM/YYYY')}` +
                            (intervalData?.end_date ? ` al ${moment(intervalData?.end_date).format('DD/MM/YYYY')}` : '')
                          }
                          size={'13px'}
                        />
                      </Box>
                    </ListItem>
                  );
                })}

                <Box
                  sx={{
                    width: '65px',
                    height: '30px',
                    m: '15px auto'
                  }}
                >
                  <UIButton
                    variant={'copy'}
                    onClick={() => {
                      setSelectedDay(row);
                      setSelectedTime(null);
                      setEditMode(true);
                    }}
                    icon={<IconPlus stroke={2} size={'20px'} />}
                    sxCustom={{
                      width: '100%',
                      bgcolor: '#FECE2E',
                      color: '#FFF',
                      boxShadow: '0px 2px 4px 0px #00000040'
                    }}
                  />
                </Box>
              </List>
            </Collapse>
            <Divider />
          </React.Fragment>
        ))}

      {selectedOffice?.id && (
        <>
          <Box>
            <ListItemButton
              onClick={() =>
                setOpen({
                  ...open,
                  exceptions: !open.exceptions
                })
              }
            >
              <ListItemText primary={convertDay(-1, timesExceptions?.length)} />
              {open.exceptions ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.exceptions} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                sx={{
                  mb: 2
                }}
              >
                {timesExceptions?.map((interval, index) => (
                  <ListItem key={index} sx={{ pl: 4, width: '90%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                    <ListItemIcon
                      sx={{
                        mr: 2
                      }}
                    >
                      <Button
                        type={'button'}
                        variant={'contained'}
                        onClick={() => {
                          setSelectedException(interval);
                          setExceptionMode(true);
                        }}
                      >
                        <IconEdit aria-label="delete" stroke={1.5} size="1rem" />
                      </Button>
                    </ListItemIcon>
                    <ListItemText
                      width={'full'}
                      primary={`${interval.start_hour} - ${interval.end_hour}`}
                      secondary={`Il giorno ${moment(interval?.start_date).format('DD/MM/YYYY')}`}
                    />
                  </ListItem>
                ))}
                <ListItem sx={{ pl: 4, width: '90%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                  <ListItemIcon
                    sx={{
                      mr: 2
                    }}
                  >
                    <Button
                      type={'button'}
                      variant={'contained'}
                      color={'primary'}
                      sx={{
                        ml: 5
                      }}
                      title={'Aggiungi giorno singolo'}
                      onClick={() => {
                        setExceptionMode(true);
                      }}
                    >
                      Aggiungi eccezione
                    </Button>
                  </ListItemIcon>
                </ListItem>
              </List>
            </Collapse>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button
              type={'button'}
              variant={'contained'}
              color={'primary'}
              sx={{
                ml: 5
              }}
              title={'Aggiungi festività'}
              onClick={() => setHolidaysMode(true)}
            >
              Aggiungi festività
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
export default CalendarTimes;
