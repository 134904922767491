import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IconArrowBack, IconPigMoney, IconShoppingCart, IconUsers } from '@tabler/icons-react';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import UITypography from '../../utilities/Typography';
import { DashboardCard } from '../dashboard/view';
import { useShop } from '../../../hooks/Shop/useShop';
import moment from 'moment/moment';
import ArrowRight from 'assets/images/icons/arrowRight.svg';
import { Image } from '@chakra-ui/react';
import ModalEarningUsers from './modalEarningsUsers';
import ModalEarningsMonth from './modalEarningsMonth';
import { UIButton } from '../../utilities/Button';
import { useNavigate } from 'react-router-dom';

const EarningsView = () => {
  const [commissionsData, setCommissionsData] = useState(null);
  const [currentCommission, setCurrentCommission] = useState(null);
  const [selectedComission, setSelectedCommission] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [usersMode, setUsersMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const navigate = useNavigate();

  const { onGetCommissionsData, onListShopMonthlyCommissions, onGetCommission } = useShop();

  useEffect(() => {
    onGetCommissionsData().then((res) => {
      setCommissionsData(res);
    });
    onGetCommission({
      month: moment().format('YYYY-MM')
    }).then((res) => {
      setCurrentCommission(res);
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setUsersMode(false);
    setViewMode(false);
    setSelectedCommission(null);
    setForceUpdate(true);
  }, []);

  const prepareValues = (earnings) =>
    earnings?.map((earning) => {
      return {
        earning,
        monthEl: (
          <UITypography
            variant={'Oxygen700'}
            size={'14px'}
            title={moment(earning?.month).format('MMMM YYYY')}
            sxCustom={{
              textTransform: 'uppercase'
            }}
          />
        ),
        earningEl: <UITypography variant={'Oxygen700'} size={'14px'} title={`${earning?.amount ? earning?.amount?.toFixed(2) : 0}€`} />,
        statusEl: (
          <Box
            sx={{
              bgcolor: earning?.payed ? '#B5FDC1' : '#FDF2B5',
              width: '100%',
              p: '10px',
              borderRadius: '8px',
              border: '1px solid #D0D5DD',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
          >
            {earning?.payed ? (
              <UITypography variant={'Oxygen700'} size={'11px'} title={'Pagato'} />
            ) : (
              <UITypography variant={'Oxygen700'} size={'11px'} title={'Da pagare'} />
            )}
          </Box>
        ),
        arrowEl: (
          <Image
            src={ArrowRight}
            sx={{
              width: '18px'
            }}
          />
        ),
        actionsEl: <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}></Box>
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalEarningUsers modalOpen={usersMode} handleModalClose={handleModalClose} />
      <ModalEarningsMonth modalOpen={viewMode} handleModalClose={handleModalClose} commission={selectedComission} />

      <UIButton variant={'cancel'} onClick={() => navigate('/dashboard')} icon={<IconArrowBack stroke={'1.64'} size={'26px'} />} />

      <UIPageHeader
        title={'I tuoi guadagni'}
        subtitle={'Controlla i guadagni che hai avuto con i prodotti consigliati ai pazienti e tieni traccia delle tue attività.'}
      />

      {commissionsData && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <DashboardCard
              minified={true}
              icon={<IconPigMoney stroke={1.64} size={'21px'} />}
              title={'Ricavo totale'}
              titleSize={'12px'}
              minValue={commissionsData?.total_commissions?.actual_start}
              maxValue={commissionsData?.total_commissions?.next_goal}
              value={commissionsData?.total_commissions?.total}
              subtitle={
                <UITypography
                  variant={'Oxygen700'}
                  size={'14px'}
                  title={`${commissionsData?.total_commissions?.total_formatted}`}
                  sxCustom={{
                    color: '#232D42'
                  }}
                />
              }
            />
            <DashboardCard
              minified={true}
              icon={<IconPigMoney stroke={1.64} size={'21px'} />}
              title={'Ricavo mensile'}
              titleSize={'12px'}
              minValue={commissionsData?.monthly_commissions?.actual_start}
              maxValue={commissionsData?.monthly_commissions?.next_goal}
              value={commissionsData?.monthly_commissions?.total}
              subtitle={
                <UITypography
                  variant={'Oxygen700'}
                  size={'14px'}
                  title={`${commissionsData?.monthly_commissions?.total_formatted}`}
                  sxCustom={{
                    color: '#232D42'
                  }}
                />
              }
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <DashboardCard
              minified={true}
              icon={<IconShoppingCart stroke={1.64} size={'21px'} />}
              title={'Totale ordini'}
              titleSize={'12px'}
              minValue={commissionsData?.total_orders?.actual_start}
              maxValue={commissionsData?.total_orders?.next_goal}
              value={commissionsData?.total_orders?.total}
              subtitle={
                <UITypography
                  variant={'Oxygen700'}
                  size={'14px'}
                  title={`${commissionsData?.total_orders?.total_formatted}`}
                  sxCustom={{
                    color: '#232D42'
                  }}
                />
              }
            />
            <DashboardCard
              minified={true}
              icon={<IconUsers stroke={1.64} size={'21px'} />}
              title={'Chi ha ordinato'}
              titleSize={'12px'}
              minValue={commissionsData?.total_users?.actual_start}
              maxValue={commissionsData?.total_users?.next_goal}
              value={commissionsData?.total_users?.total}
              arrow={true}
              onClick={() => {
                setUsersMode(true);
              }}
              subtitle={
                <UITypography
                  variant={'Oxygen700'}
                  size={'14px'}
                  title={`${commissionsData?.total_users?.total_formatted}`}
                  sxCustom={{
                    color: '#232D42'
                  }}
                />
              }
            />
          </Box>
        </>
      )}

      <SectionHeader title={'Mese corrente'} />

      <Box
        sx={{
          bgcolor: '#FDF2B5',
          border: '1px solid #FECE2E',
          borderRadius: '8px',
          p: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={() => {
          setSelectedCommission(currentCommission);
          setViewMode(true);
        }}
      >
        <Box>
          <UITypography
            variant={'Oxygen700'}
            size={'12px'}
            title={moment(currentCommission?.month).format('MMMM YYYY')}
            sxCustom={{
              textTransform: 'uppercase',
              color: '#101828'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px'
          }}
        >
          <UITypography
            variant={'Oxygen700'}
            size={'14px'}
            title={`${currentCommission?.amount ? currentCommission?.amount?.toFixed(2) : 0}€`}
            sxCustom={{
              textTransform: 'uppercase',
              color: '#101828'
            }}
          />
          <Image
            src={ArrowRight}
            sx={{
              width: '18px'
            }}
          />
        </Box>
      </Box>

      <SectionHeader title={'Mesi precedenti'} />

      <UITable
        columns={[
          {
            name: 'Mese',
            value: 'monthEl',
            size: 100
          },
          {
            name: 'Guadagno',
            value: 'earningEl',
            size: 100
          },
          {
            name: 'Stato',
            value: 'statusEl',
            size: 100,
            muiTableHeadCellProps: {
              align: 'center'
            }
          },
          {
            name: '',
            value: 'arrowEl',
            size: 15
          }
        ]}
        rowClick={(data) => {
          setSelectedCommission(data?.row?.original?.earning);
          setViewMode(true);
        }}
        prepareValues={prepareValues}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri bonifici da mostrare'}
        infiniteScrollFunction={onListShopMonthlyCommissions}
      />
    </Box>
  );
};
export default EarningsView;
